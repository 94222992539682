import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';

import { AppComponent } from './app.component';
import { NewsComponent } from './news/news.component';
import { MusicComponent } from './music/music.component';
import { PhotosComponent } from './photos/photos.component';
import { ArticlesComponent } from './articles/articles.component';
import { RouterModule, Routes } from '@angular/router';

export const routerConfig: Routes = [
  {
    path: 'news',
    component: NewsComponent
  },
  {
    path: 'music',
    component: MusicComponent
  },
  {
    path: 'photos',
    component: PhotosComponent
  },
  {
    path: 'articles/:id',
    component: ArticlesComponent
  },
  {
    path: 'articles',
    component: ArticlesComponent
  },
  {
    path: '',
    redirectTo: '/news',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/news',
    pathMatch: 'full'
  }
];

@NgModule({
  declarations: [
    AppComponent,
    NewsComponent,
    MusicComponent,
    PhotosComponent,
    ArticlesComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,

    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),

    RouterModule.forRoot(routerConfig)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }