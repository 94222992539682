<nav class="navbar navbar-expand navbar-light bg-light">
  <div class="collapse navbar-collapse">
    <ul class="nav navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="news" routerLinkActive="active">News</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="music" routerLinkActive="active">Music</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="photos" routerLinkActive="active">Photos</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="articles" routerLinkActive="active">Articles</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="https://blakeboyd.dev">.dev</a>
      </li>
    </ul>
  </div>
</nav>

<router-outlet></router-outlet>

<footer class="footer mt-auto">
  <div class="container">
      <p class="small text-center text-muted">Copyright © 2019-2023 <a href="mailto:blake.boyd.website@gmail.com">Blake Boyd</a>. All rights reserved.</p>
  </div>
</footer>