import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Article, DocumentService } from '../document.service';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit {
  public articleList: Article[] = [];
  public articleContent: string = '';

  public sidebarHiddenOnMobile = true;

  constructor(private route: ActivatedRoute, private documentService: DocumentService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.documentService.getArticles()
        .subscribe(result => this.articleList = result);
      
      const title = params.get('id');
      
      if (!title) {
        this.sidebarHiddenOnMobile = false;
        return;
      }

      this.documentService.getArticleContent(title)
        .subscribe(result =>
          this.articleContent = result,
          () => this.articleContent = '<p>Sorry, this article wasn\'t found!</p>');
    });
  }

}
