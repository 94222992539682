<div class="container">
    <div class="row">
        <div class="m-2 btn-group">
            <button *ngFor="let photoTag of photoTags" (click)="updatePhotoFilter(photoTag.tag)" [ngClass]="{active : photoFilter === photoTag.tag}" class="btn btn-primary">{{photoTag.display}}</button>
        </div>
    </div>
    <div class="row">
        <div class="d-flex flex-wrap">
            <img class="m-2 pointer" *ngFor="let photo of visiblePhotos" [src]="'assets/photos/' + photo.small" (click)="openModal(template, photo.large)" />
        </div>
    </div>
    <div class="row">
        <pagination [itemsPerPage]="itemsPerPage" [totalItems]="filteredPhotos.length" [(ngModel)]="currentPage" (pageChanged)="updatePage($event.page)"></pagination>
    </div>
</div>

<ng-template #template>
    <img [src]="'assets/photos/' + photoRef" />
</ng-template>