import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DocumentService, Photo } from '../document.service';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.css']
})
export class PhotosComponent implements OnInit {
  public photos: Photo[] = [];
  public filteredPhotos: Photo[] = [];
  public visiblePhotos: Photo[] = [];
  
  public photoFilter: string = 'all';
  public photoTags: any = [
    { tag: 'all', display: 'All' },
    { tag: 'infrared', display: 'Infrared' },
    { tag: 'visible', display: 'Visible' },
    { tag: 'ultraviolet', display: 'Ultraviolet' }
  ];

  public currentPage: number = 1;
  public itemsPerPage: number = 15;

  public modalRef: BsModalRef;
  public photoRef: string;

  constructor(private documentService: DocumentService,
              private bsModalService: BsModalService) { }

  ngOnInit(): void {
    this.documentService.getPhotos()
      .subscribe((result) => {
        this.photos = result;
        this.updatePhotoFilter('all');
      });
  }

  public openModal(template: TemplateRef<any>, bigPhoto: string): void {
    this.photoRef = bigPhoto;
    this.modalRef = this.bsModalService.show(template);
    this.modalRef.setClass('lightbox');
  }

  public updatePhotoFilter(tag: string): void {
    this.photoFilter = tag;
    
    this.filteredPhotos = this.photos.filter(p =>
      p.tags.some(t =>
        tag === 'all' || (t === tag)
      ));

    this.visiblePhotos = this.filteredPhotos.slice(0, this.itemsPerPage)

    this.updatePage(1);
  }

  public updatePage(page: number): void {
    this.visiblePhotos = this.filteredPhotos.slice((page - 1) * this.itemsPerPage, page * this.itemsPerPage);
  }
}
