import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export class Photo {
  small: string;
  large: string;
  tags: string[];
}

export class Article {
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  constructor(private httpClient: HttpClient) { }

  public getPhotos(): Observable<Photo[]> {
    return this.httpClient.get<Photo[]>('assets/photos/metadata.json');
  }

  public getArticles(): Observable<Article[]> {
    return this.httpClient.get<Article[]>('assets/articles/metadata.json');
  }

  public getArticleContent(title: string): Observable<string> {
    return this.httpClient.get('assets/articles/' + title + '.html', {responseType: 'text'});
  }
}
